.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

.layout-container {
  height: 100vh;
  width: 100vw;
  margin: -10px;
}

.bg-muted {
  background: #f5f5f5;
}

.mt-5 {
  margin-top: 5rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.p-3 {
  padding: 3rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}

.offline {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  height: 25px;
  z-index: 10;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  background-color: #9c0f0f;
  color: white;
  justify-content: center;
  align-items: center;
}

.online {
  position: absolute;
  margin-bottom: 0;
  bottom: 0;
  height: 25px;
  z-index: 10;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  background-color: #00b96b;
  color: white;
  justify-content: center;
  align-items: center;
  animation: removingTag 1s;
  animation-fill-mode: forwards;
}

@keyframes removingTag {
  to {
    display: none;
  }
}

.multi-range-slider .bar-inner {
  border-width: 0 !important;
}

@media only screen and (min-width:1200px){
  .trimWidth{
    width:25%;
  }
}

@media only screen and (min-width:992px) and (max-width:1200px){
  .trimWidth{
    width:35%;
  }
}

@media only screen and (min-width:768px) and (max-width:992px){
  .trimWidth{
    width:50%;
  }
}

@media only screen and (min-width:576px) and (max-width:768px){
  .trimWidth{
    width:60%;
  }
}

@media only screen and (max-width:576px){
  .trimWidth{
    width:100%;
  }
}

/* video editor loader */

.loader {
	height: '100%';
	width: '100%';
	opacity: 1;
  position: absolute;
	transition: opacity 0.35s;
	background-color: rgba(49, 49, 49, 0.534);
  /* background-color: red; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* inset: 0; */
	z-index: 99;
}

.loader h2 {
	color: white;
}
.loader.fadeout {
	opacity: 0;
}
.cent {
	/* width: 100%; */
	/* height: 50%; */
	display: flex;
	justify-content: center;
	align-items: center;
}
.loading-indicator {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.loading-indicator div {
	position: absolute;
	border: 4px solid #fff;
	opacity: 1;
	border-radius: 50%;
	animation: loading-indicator 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading-indicator div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes loading-indicator {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.result-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}